import { CircularProgress } from '@mui/material';
import React, { Suspense } from 'react';

interface SuspensedProps {
    children: React.ReactNode;
}

export const Suspensed: React.FC<SuspensedProps> = ({ children }: SuspensedProps) => {
    return <Suspense fallback={<CircularProgress className="loader" />}>{children}</Suspense>;
};
