export const NBR_RESULTS_PER_PAGE = 10;

export interface DestinationListApiParams {
    departureCitySlug: string;
    departureDateTime?: Date;
    nbrResults: number;
    interests?: string;
    minDurationSec?: number;
    maxDurationSec?: number;
    maxNbTransfers?: number;
}
