import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { AnalyticsContextProvider } from './utils/analytics-context';
import { Suspensed } from './utils/route';
import { SearchContextProvider } from './utils/search-context';

const Home = React.lazy(() => import('./pages/home/Home'));
const DestinationList = React.lazy(() => import('./pages/destination-list/DestinationList'));
const DestinationDetail = React.lazy(() => import('./pages/destination-detail/DestinationDetail'));
const PageNotFound = React.lazy(() => import('./pages/not-found/NotFound'));
const BlogList = React.lazy(() => import('./pages/blog/blog-list/BlogList'));
const BlogArticleDetail = React.lazy(() => import('./pages/blog/blog-article-detail/BlogArticleDetail'));

const rootNode = document.getElementById('root');

if (rootNode == null) {
    throw new Error('No root element');
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#4B0BD3',
            light: '#F8F8FF',
            dark: '#420194',
        },
        secondary: {
            main: '#0030C9',
            light: '#96AFFF',
            dark: '#032287',
        },
        error: {
            main: '#EC6762',
            light: '#FDDEDD',
            dark: '#BB504C',
        },
        warning: {
            main: '#EBBC17',
            light: '#FFF0D9',
            dark: '#BE9400',
        },
        success: {
            main: '#2AC9BF',
            light: '#DEF2F1',
            dark: '#21958E',
        },
        info: {
            main: '#1B1E33',
            light: '#8690B0',
            dark: '#555972',
        },
    },
    typography: {
        fontFamily: [
            'Urbanist',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            '"Fira Sans"',
            '"Droid Sans"',
            '"Helvetica Neue"',
            'sans-serif',
        ].join(','),
    },
});

const root = ReactDOM.createRoot(rootNode);

root.render(
    <AnalyticsContextProvider>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <SearchContextProvider>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Suspensed>
                                    <Home />
                                </Suspensed>
                            }
                        />
                        <Route
                            path="/destination"
                            element={
                                <Suspensed>
                                    <DestinationList />
                                </Suspensed>
                            }
                        />
                        <Route
                            path="/destination/detail/:arrivalCitySlug?/:departureCitySlug?"
                            element={
                                <Suspensed>
                                    <DestinationDetail />
                                </Suspensed>
                            }
                        />
                        <Route
                            path="/blog"
                            element={
                                <Suspensed>
                                    <BlogList />
                                </Suspensed>
                            }
                        />
                        <Route
                            path="/blog/:articleSlug"
                            element={
                                <Suspensed>
                                    <BlogArticleDetail />
                                </Suspensed>
                            }
                        />
                        <Route
                            path="*"
                            element={
                                <Suspensed>
                                    <PageNotFound />
                                </Suspensed>
                            }
                        />
                    </Routes>
                </SearchContextProvider>
            </BrowserRouter>
        </ThemeProvider>
    </AnalyticsContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
