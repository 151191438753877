import * as amplitude from '@amplitude/analytics-browser';
import { ANALYTICS_API_KEY, ANALYTICS_DATA_COLLECTION_AUTHORIZED_STORAGE_KEY } from '../constants';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

const EVENT_CATEGORIES_LABELS = {
    SEARCH: ['CITY_SEARCH', 'DESTINATION_SEARCH', 'DESTINATION_SEARCH_MORE'],
    INTERACTION: ['MAP_MARKER_CLICK', 'BOOK_TICKET_CLICK'],
};

type EventCategory = keyof typeof EVENT_CATEGORIES_LABELS;
type EventAction<T extends EventCategory> = (typeof EVENT_CATEGORIES_LABELS)[T][number];

interface ContextState {
    trackerInitialized: boolean;
    acceptCookies: () => void;
    declineCookies: () => void;
    trackEvent: <T extends EventCategory>(
        category: T,
        action: EventAction<T>,
        properties?: Record<string, any>,
    ) => void;
}

const initialState: ContextState = {
    trackerInitialized: window.localStorage.getItem(ANALYTICS_DATA_COLLECTION_AUTHORIZED_STORAGE_KEY) === 'true',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    acceptCookies: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    declineCookies: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    trackEvent: () => {},
};

const Context = createContext<ContextState>(initialState);

const useContext_ = () => useContext(Context);

const Provider = ({ children }: { children: React.ReactNode }) => {
    const [trackerInitialized, setTrackerInitialized] = useState(false);

    const initTracker = useCallback(() => {
        amplitude.init(ANALYTICS_API_KEY, undefined, {
            defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true },
        });
    }, []);

    const acceptCookies = useCallback(() => {
        window.localStorage.setItem(ANALYTICS_DATA_COLLECTION_AUTHORIZED_STORAGE_KEY, 'true');
        initTracker();
    }, []);

    const declineCookies = useCallback(() => {
        window.localStorage.setItem(ANALYTICS_DATA_COLLECTION_AUTHORIZED_STORAGE_KEY, 'false');
    }, []);

    useEffect(() => {
        if (window.localStorage.getItem(ANALYTICS_DATA_COLLECTION_AUTHORIZED_STORAGE_KEY) === 'true') {
            initTracker();
            setTrackerInitialized(true);
        }
    }, []);

    const trackEvent = <T extends EventCategory>(
        category: T,
        action: EventAction<T>,
        properties?: Record<string, any>,
    ) => {
        if (!trackerInitialized) {
            return;
        }

        amplitude.track(`${category}-${action}`, properties ? properties : undefined);
    };

    const value = useMemo(() => {
        return {
            trackerInitialized,
            acceptCookies,
            declineCookies,
            trackEvent,
        };
    }, [trackerInitialized, acceptCookies, declineCookies, trackEvent]);

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { useContext_ as useAnalyticsContext, Provider as AnalyticsContextProvider };
