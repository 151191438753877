import { IconName } from './assets/Icon';
import { BlogArticleCategory } from './enums/blog-article';
import { Interest } from './enums/interest';
import { convertEnumToList } from '@stephenprn/typescript-common/lib/utils/object';

export const isProduction = process.env.NODE_ENV === 'production';

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 30;

export const ANALYTICS_API_KEY = 'e1dab831b5ac37a70f38bba0fec1667c';
export const ANALYTICS_DATA_COLLECTION_AUTHORIZED_STORAGE_KEY = 'analytics_data_collection_authorized';

export const INTERESTS_LABELS = {
    [Interest.CULTURE]: 'Culture',
    [Interest.NATURE]: 'Campagne',
    [Interest.BEACH]: 'Plage',
    [Interest.MOUNTAIN]: 'Montagne',
    [Interest.HIKING]: 'Randonnée',
    [Interest.VELO]: 'Vélo',
};

export const INTERESTS_ICONS: { [interest: string]: IconName } = {
    [Interest.CULTURE]: 'interestCulture',
    [Interest.NATURE]: 'interestNature',
    [Interest.BEACH]: 'interestBeach',
    [Interest.MOUNTAIN]: 'interestMountain',
    [Interest.HIKING]: 'interestHiking',
    [Interest.VELO]: 'interestVelo',
};

export type SearchBarItem = 'CitySearchBar' | 'InterestsSelector' | 'DatesSelector' | 'FilterSelector' | null;

export const INTERESTS: Interest[] = convertEnumToList(Interest);

// export const MAP_TILES_URL = 'https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=dJNLFLXZR4GdnVnBMKpx';
// export const MAP_TILES_URL = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png';
export const MAP_TILES_URL =
    'https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=2eadcbc2-30d5-41ce-9366-db248cae16fa';

export const HEADER_HEIGHT = 70;

export const BLOG_ARTICLE_CATEGORY_LABELS = {
    [BlogArticleCategory.TRICK]: 'Astuce',
    [BlogArticleCategory.NEWS]: 'Actualité',
    [BlogArticleCategory.INSPIRATION]: 'Inspiration',
    [BlogArticleCategory.ECOLOGY]: 'Ecologie',
};
